import { SelectableValue } from '@grafana/data';
import { getTranslation } from 'types/translation';
import { Action, LengthUnit, IsoClassificationsProps } from 'types/types';
import {
  IdCardElementsEN,
  IdCardElementsFR,
  INCIDENT_ADVISOR_PANEL_INDEX,
  ALERT_ROW_INDEX,
  VIB_SEV_ALARM_PANEL_INDEX,
  VIB_SEV_TRIP_PANEL_INDEX,
  SESSION_ID_TEMPLATE,
  MYSQL_DATASOURCE,
} from './constants';
import { fetchNewDashboardJson } from './fetchNewJson';
import { getDefaultTemplateVars } from './getDefaultTemplateVars';
import { getDatasource, modifyQueries } from './helpers';

/**
 * Get json dashboard model, fill identity card elements, handle units, interpolate template variables.
 * Used for dashboard creation.
 */
export const getNewJsonFilled = async (
  lang: string,
  macAddress: string,
  temperatureUnit: SelectableValue,
  lengthUnit: SelectableValue,
  dispatch: (value: Action) => void,
  isoClassifications: IsoClassificationsProps
) => {
  const jsonModel = await fetchNewDashboardJson(dispatch, 'Rotating').catch((err) => {
    console.log(err);
    return;
  });

  const newJson = { ...jsonModel };
  const dico = getTranslation(lang, isoClassifications);

  newJson.dashboard.title = `${macAddress}`;
  newJson.dashboard.tags = ['Machine'];
  const ds: any = await getDatasource(MYSQL_DATASOURCE);

  let sessionTemplate = SESSION_ID_TEMPLATE;
  sessionTemplate.datasource.uid = ds.uid;

  const otherTemplates = getDefaultTemplateVars(macAddress, lang, lengthUnit);
  newJson.dashboard.templating.list = [...otherTemplates, sessionTemplate];

  // !! Language dependant !!
  // Inject id card elements in Incident Advisor panel
  const idCardDefaultElements = lang === 'EN' ? [...IdCardElementsEN] : [...IdCardElementsFR];
  idCardDefaultElements[1].value = macAddress; // mac_address
  idCardDefaultElements[2].value = macAddress; // machine_name

  newJson.dashboard.panels[INCIDENT_ADVISOR_PANEL_INDEX].idCardElements = idCardDefaultElements;

  // Change default threshold vibration severity value (default Class I 1.8 - 4.5 mm/s).
  // As the dashboard model json has already default values in mm/s, only modify these fields if length unit is inch
  if (lengthUnit.value !== LengthUnit.meter) {
    fillVibrationSeverityThreshold(newJson, isoClassifications);
  }

  const modifiedQueries = await modifyQueries(newJson, macAddress, temperatureUnit.value, lengthUnit.value, dico);
  newJson.dashboard.panels = modifiedQueries;
  return newJson;
};

/**
 * Only used on dashboard creation.
 * Inject default vibration severity threshold value in in/s, class I
 */
export const fillVibrationSeverityThreshold = (
  newJson: { dashboard: { panels: any[] } },
  isoClassifications: IsoClassificationsProps
) => {
  const newJsonPanels = newJson.dashboard.panels[ALERT_ROW_INDEX].panels;
  // alarm evaluator param
  newJsonPanels[VIB_SEV_ALARM_PANEL_INDEX].alert.conditions[0].evaluator.params[0] =
    isoClassifications.vibrationSeverityIsoIns.class1.alarm;
  // alarm threshold value
  newJsonPanels[VIB_SEV_ALARM_PANEL_INDEX].fieldConfig.defaults.thresholds.steps[1].value =
    isoClassifications.vibrationSeverityIsoIns.class1.alarm;
  // trip evaluator param
  newJsonPanels[VIB_SEV_TRIP_PANEL_INDEX].alert.conditions[0].evaluator.params[0] =
    isoClassifications.vibrationSeverityIsoIns.class1.trip;
  // trip threshold value
  newJsonPanels[VIB_SEV_TRIP_PANEL_INDEX].fieldConfig.defaults.thresholds.steps[1].value =
    isoClassifications.vibrationSeverityIsoIns.class1.trip;
};
