import React, { useState, useEffect } from 'react';
import { dateTimeAsMoment, SelectableValue } from '@grafana/data';
import { LinkButton, Badge, RadioButtonGroup, Tooltip, Select } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { DashboardsProps, LengthUnit } from 'types/types';
import { LENGTH_OPTIONS } from 'utils/constants';

interface UpdateDashboardRowProps {
  dashboard: DashboardsProps;
  tableHeaders: any[];
}

export const UpdateDashboardRow: React.FunctionComponent<UpdateDashboardRowProps> = ({ dashboard, tableHeaders }) => {
  const { state, dispatch } = useAppState();
  const { dashboardsToUpdate } = state;

  const getAnalysisProfileOptions = (lang: string) => {
    const optionsFR = [
      { label: 'Machine tournante', value: 'Machine tournante' },
      { label: 'Machine non-tournante', value: 'Machine non-tournante' },
      { label: 'Vanne', value: 'Vanne' },
    ];
    const optionsEN = [
      { label: 'Rotating', value: 'Rotating' },
      { label: 'Non-rotating', value: 'Non-rotating' },
      { label: 'Valve', value: 'Valve' },
    ];

    return lang === 'FR' ? optionsFR : optionsEN;
  };

  const [isChecked, setIsChecked] = useState(true);
  const { id, title, dashboardUrl, tags, macAddress, created, updated, hasIA, lengthUnit, profile, lang } = dashboard;
  const [analysisProfileSelected, setAnalysisProfileSelected] = useState(profile);
  const analysisProfileOptions = getAnalysisProfileOptions(lang);

  const isCheckedDisabled = macAddress === 'no mac address' ? true : false;

  const handleInputChange = () => {
    const updatedDashboardList = dashboardsToUpdate.filter((dashId) => dashId.id !== id);
    if (!isChecked) {
      dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: [...updatedDashboardList, dashboard] });
    } else {
      dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: updatedDashboardList });
    }
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (macAddress === 'no mac address') {
      setIsChecked(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!dashboardsToUpdate.length) {
      setIsChecked(false);
    }
  }, [dashboardsToUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUnitChange = (e: LengthUnit) => {
    const newDashboardToUpdate = [...dashboardsToUpdate];
    const dashboardIndex = newDashboardToUpdate.findIndex((dashId) => dashId.id === id);
    newDashboardToUpdate[dashboardIndex].lengthUnit = e;
    dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: newDashboardToUpdate });
  };

  const handleProfileChange = (e: SelectableValue) => {
    const newDashboardToUpdate = [...dashboardsToUpdate];
    const dashboardIndex = newDashboardToUpdate.findIndex((dashId) => dashId.id === id);
    newDashboardToUpdate[dashboardIndex].newProfile = e.value;
    setAnalysisProfileSelected(e.value);
    dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: newDashboardToUpdate });
  };

  const detailsContent = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div> Created: {dateTimeAsMoment(created).format('MM/DD/YYYY HH:mm')}</div>
        <div>Updated: {dateTimeAsMoment(updated).format('MM/DD/YYYY HH:mm')}</div>
        <div>Incident Advisor: {hasIA ? 'YES' : 'NO!'}</div>
        <div>
          Tags:{' '}
          {tags.map((tag: string, index: number) => (
            <span style={{ marginRight: '3px' }} key={`${tag}${index}`}>
              <Badge text={tag} color="blue" />
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <tr style={{ background: isCheckedDisabled ? '#f8dee8' : '' }}>
      {tableHeaders.map((header) => (
        <td key={header.title} style={{ width: header.width, color: isCheckedDisabled ? '#d95e5e' : '' }}>
          {header.title === 'UPDATE' && (
            <input type="checkbox" checked={isChecked} onChange={handleInputChange} disabled={isCheckedDisabled} />
          )}
          {header.title === 'DASHBOARD NAME' && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={header.title !== 'URL' && !isCheckedDisabled ? handleInputChange : undefined}
            >
              {title}
            </div>
          )}
          {header.title === 'MAC ADDRESS' && macAddress}
          {header.title === 'DETAILS' && (
            <Tooltip content={detailsContent} placement={'right'}>
              <div style={{ cursor: 'pointer' }}>Infos</div>
            </Tooltip>
          )}
          {header.title === 'LENGTH UNIT' && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <RadioButtonGroup
                size={'sm'}
                options={LENGTH_OPTIONS}
                value={lengthUnit ? lengthUnit : LengthUnit.meter} // set meter by default is no length unit variable
                onChange={(e) => handleUnitChange(e!)}
                disabled={!isChecked}
              />
            </div>
          )}
          {header.title === 'ANALYSIS PROFILE' && (
            <div>
              <Select
                options={analysisProfileOptions}
                value={analysisProfileSelected}
                disabled={!isChecked}
                onChange={(e) => handleProfileChange(e)}
              />
            </div>
          )}
          {header.title === 'URL' && (
            <LinkButton onClick={() => window.open(dashboardUrl)} icon="eye" variant="secondary">
              View
            </LinkButton>
          )}
        </td>
      ))}
    </tr>
  );
};
