import React from 'react';
import { css } from '@emotion/css';
import { useStyles2, Button, IconName } from '@grafana/ui';
import { useAppState } from '../app-context/AppStateContext';
import { UserInfos } from 'components/UserInfos';
import { CurrentProcessType } from 'types/types';

interface MainMenuProps {}

export const MainMenu: React.FunctionComponent<MainMenuProps> = () => {
  const { state, dispatch } = useAppState();
  const { user } = state;

  const styles = useStyles2(getStyles);

  const MENU_BUTTONS = [
    {
      title: 'DASHBOARD CREATION',
      buttonClick: () =>
        dispatch({
          type: 'SET_CURRENT_PROCESS',
          payload: CurrentProcessType.creation,
        }),
      icon: 'plus' as IconName,
    },
    {
      title: 'UPDATE DASHBOARDS WITH LAST RELEASE',
      buttonClick: () =>
        dispatch({
          type: 'SET_CURRENT_PROCESS',
          payload: CurrentProcessType.update,
        }),
      icon: 'sync' as IconName,
    },
  ];

  return (
    <div className={styles.buttonContainer}>
      <div>
        <UserInfos />
      </div>
      {user.orgRole !== 'Admin' && (
        <div>As a {user.orgRole}, you are not allowed to use this plugin. Only accessible by admin.</div>
      )}
      {user.orgRole === 'Admin' && (
        <>
          {MENU_BUTTONS.map(({ title, icon, buttonClick }) => (
            <Button key={title} size={'lg'} className={styles.buttonStyle} onClick={buttonClick} icon={icon}>
              {title}
            </Button>
          ))}
        </>
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    buttonContainer: css`
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: auto;
      max-width: 550px;
      overflow: auto;
      width: 100%;
    `,
    buttonStyle: css`
      margin: 3px;
    `,
  };
};
