import { getBackendSrv } from '@grafana/runtime';
import { DashboardsProps, IsoClassificationsProps, LengthUnit, OldIdCardProps, TemperatureUnit } from 'types/types';
import {
  checkPrevDashTemperatureUnit,
  findPanelIndexByType,
  getDatasource,
  getVibratoryThreshold,
  injectIdCardValues,
  modifyQueries,
} from 'utils/helpers';
import {
  getLengthUnitTemplate,
  INCIDENT_ADVISOR_PLUGIN,
  MYSQL_DATASOURCE,
  OLD_IDENTITY_CARD_PLUGIN,
} from './constants';
import { DicoProps } from 'types/translation';
import { getDashboardAlerts } from './getDashboardAlerts';

export const generateNewDashboard = async (
  dashboardToUpdate: DashboardsProps,
  newJson: any,
  note: string,
  dico: DicoProps,
  isoClassifications: IsoClassificationsProps,
  datasourceJson: any
) => {
  if (!Object.keys(dashboardToUpdate).length) {
    return;
  }

  const getIdCardValues = () => {
    let values = [];
    const dashboardPanels = dashboardToUpdate.dashboardJson.dashboard.panels;
    let indexOfOldIdCard = findPanelIndexByType(dashboardPanels, OLD_IDENTITY_CARD_PLUGIN);
    const indexOfIncidentAdvisor = findPanelIndexByType(dashboardPanels, INCIDENT_ADVISOR_PLUGIN);

    if (indexOfOldIdCard === -1) {
      if (indexOfIncidentAdvisor !== -1) {
        values = dashboardPanels[indexOfIncidentAdvisor].idCardElements;
        if (dashboardToUpdate.profile !== dashboardToUpdate.newProfile) {
          // Update json idCard analysis_profile
          const analysisProfileIndex = values.findIndex((val: { field: string }) => val.field === 'analysis_profile');
          if (analysisProfileIndex !== -1) {
            values[analysisProfileIndex].value = dashboardToUpdate.newProfile;
          }
          // Update json idCard process_function
          const processIndex = values.findIndex((val: { field: string }) => val.field === 'process_function');
          if (processIndex !== -1) {
            values[processIndex].value =
              dashboardToUpdate.newProfile === dico.valve ? dico.processValve : dico.processRotating;
          }
          // Update json idCard type
          const typeIndex = values.findIndex((val: { field: string }) => val.field === 'type');
          if (typeIndex !== -1) {
            values[typeIndex].value = dashboardToUpdate.newProfile === dico.valve ? dico.typeValve : dico.typeRotating;
          }
        }
        return values;
      }
    } else {
      const oldIdCardValues = dashboardPanels[indexOfOldIdCard].visibleItems as OldIdCardProps[];
      for (const [key, value] of Object.entries(oldIdCardValues)) {
        values.push({ field: key, value: value.strval });
      }
    }
    return values;
  };

  const generateDashboard = async (dashboardToUpdate: DashboardsProps, datasourceJson: any) => {
    let idValues = (await getIdCardValues()) as any;
    if (!idValues) {
      console.error('error id card values');
      return;
    }

    newJson.dashboard.title = dashboardToUpdate.dashboardJson.dashboard.title;
    newJson.dashboard.uid = dashboardToUpdate.dashboardJson.dashboard.uid;
    newJson.dashboard.time = dashboardToUpdate.dashboardJson.dashboard.time;
    newJson.dashboard.tags = dashboardToUpdate.dashboardJson.dashboard.tags;
    newJson.dashboard.timezone = dashboardToUpdate.dashboardJson.dashboard.timezone;
    newJson.dashboard.refresh = dashboardToUpdate.dashboardJson.dashboard.refresh;
    newJson.dashboard.links = dashboardToUpdate.dashboardJson.dashboard.links;
    newJson.overwrite = true;
    newJson.folderId = dashboardToUpdate.dashboardJson.meta.folderId;
    newJson.message = note;

    const classIndex = idValues.findIndex((idValue: { field: string }) => idValue.field === 'class');
    const currentClass = dashboardToUpdate.newProfile === dico.valve ? dico.class1 : idValues[classIndex]?.value;

    const customIsoIndex = idValues.findIndex((idValue: { field: string }) => idValue.field === 'customIso');
    const customIso = idValues[customIsoIndex]?.value;

    const previousDefaultVibSevValue = getVibratoryThreshold(
      currentClass,
      dico,
      dashboardToUpdate.previousLengthUnit,
      isoClassifications,
      customIso
    );
    const currentDefaultVibSevValue = getVibratoryThreshold(
      currentClass,
      dico,
      dashboardToUpdate.lengthUnit,
      isoClassifications,
      customIso
    );

    await getDashboardAlerts(
      dashboardToUpdate,
      newJson,
      previousDefaultVibSevValue,
      currentDefaultVibSevValue,
      isoClassifications,
      currentClass,
      customIso
    );

    const isFahrenheit = checkPrevDashTemperatureUnit(dashboardToUpdate);
    const tempUnit = isFahrenheit ? TemperatureUnit.fahrenheit : TemperatureUnit.celsius;

    newJson.dashboard.panels = await modifyQueries(
      newJson,
      dashboardToUpdate.macAddress,
      tempUnit,
      dashboardToUpdate.lengthUnit ? dashboardToUpdate.lengthUnit : LengthUnit.meter,
      dico
    );

    const mysqlDs = await getDatasource(MYSQL_DATASOURCE);
    const WRITE_API_URL = `api/datasources/proxy/uid/${datasourceJson.uid}`;
    const IDENTITY_CARD_ENDPOINT = `${WRITE_API_URL}/identity_card`;

    /** Inject template variables in new dashboard JSON  */
    dashboardToUpdate.dashboardJson.dashboard.templating.list.forEach((template: any) => {
      // exclude session id as it's already in the new JSON
      if (template.name !== 'session_id') {
        newJson.dashboard.templating.list.push(template);
      } else {
        const sessionIdTemplateIndex = newJson.dashboard.templating.list.findIndex(
          (template: { name: string; datasource: { type: string; uid: string } }) => template.name === 'session_id'
        );
        if (sessionIdTemplateIndex !== -1) {
          newJson.dashboard.templating.list[sessionIdTemplateIndex].datasource.uid = mysqlDs.uid;
        }
      }
    });

    if (!dashboardToUpdate.hasLengthVariable) {
      newJson.dashboard.templating.list.push(
        getLengthUnitTemplate(dashboardToUpdate.lengthUnit ? dashboardToUpdate.lengthUnit : LengthUnit.meter)
      ); // if no unit selected, assign meter
    } else {
      const lengthVarIndex = newJson.dashboard.templating.list.findIndex(
        (template: { name: string }) => template.name === 'length_unit'
      );
      if (lengthVarIndex !== -1) {
        newJson.dashboard.templating.list[lengthVarIndex] = getLengthUnitTemplate(dashboardToUpdate.lengthUnit);
      }
    }
    injectIdCardValues(dashboardToUpdate.dashboardJson.dashboard, newJson, dashboardToUpdate.hasIA);

    // Add analysis_profile in identity card entry if it doesn't exist
    if (!dashboardToUpdate.hasIA) {
      const payloadUpdate = { mac_address: dashboardToUpdate.macAddress, analysis_profile: 'Rotating' };
      await getBackendSrv().put(IDENTITY_CARD_ENDPOINT, payloadUpdate, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    // Handle analysis profile change
    if (dashboardToUpdate.profile !== dashboardToUpdate.newProfile) {
      let profile = dashboardToUpdate.newProfile;
      // Translation
      if (dashboardToUpdate.lang !== 'EN') {
        switch (dashboardToUpdate.newProfile) {
          case dico.rotating:
            profile = 'Rotating';
            break;
          case dico.nonRotating:
            profile = 'Non-rotating';
            break;
          case dico.valve:
            profile = 'Valve';
            break;
          default:
            break;
        }
      }

      let processFunction = dashboardToUpdate.newProfile === dico.valve ? 'Valve' : 'Compressor';
      let machineType = dashboardToUpdate.newProfile === dico.valve ? 'Valve body' : 'Motor';
      // `UPDATE identity_card SET analysis_profile='${newProfile}', class='Class I', process_function='${processFunction}', type='${machineType}' WHERE mac_address = '${macAddress}'`;

      const payloadUpdate = {
        mac_address: dashboardToUpdate.macAddress,
        process_function: processFunction,
        type: machineType,
        machine_class_longname: 'Class I',
        analysis_profile: profile,
      };

      await getBackendSrv().put(IDENTITY_CARD_ENDPOINT, payloadUpdate, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    return newJson;
  };

  const dash = await generateDashboard(dashboardToUpdate, datasourceJson);

  return new Promise((resolve) => resolve(dash));
};
