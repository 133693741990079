import React from 'react';
import { PanelModel, PanelProps } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { AppStateProvider, useAppState } from 'app-context/AppStateContext';
import { UpdateDashboardsContainer } from './components/update-dashboards/UpdateDashboardsContainer';
import { CreateDashboardsContainer } from './components/create-dashboards/CreateDashboardsContainer';
import { MainMenu } from './components/MainMenu';
import { CurrentProcessType } from 'types/types';
import { SIMPLEJSON_DATASOURCE, URL_GET_DATASOURCE_BY_NAME } from 'utils/constants';

export interface Props {
  panel: PanelModel;
}

export const SimplePanel: React.FunctionComponent<PanelProps> = () => {
  return (
    <AppStateProvider>
      <Container />
    </AppStateProvider>
  );
};

interface ContainerProps {}

const Container: React.FunctionComponent<ContainerProps> = () => {
  const { state, dispatch } = useAppState();
  const { currentProcess } = state;

  const getDatasourceJSON = async () => {
    const jsonDs = await getBackendSrv().get(`${URL_GET_DATASOURCE_BY_NAME}${SIMPLEJSON_DATASOURCE}`);
    dispatch({ type: 'ADD_DATASOURCES', payload: jsonDs });
  };

  React.useEffect(() => {
    getDatasourceJSON();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {currentProcess === CurrentProcessType.null && <MainMenu />}
      {currentProcess === CurrentProcessType.update && <UpdateDashboardsContainer />}
      {currentProcess === CurrentProcessType.creation && <CreateDashboardsContainer />}
    </>
  );
};
