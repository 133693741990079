import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import React from 'react';
import { getColorStatus } from 'utils/helpers';
import { DashboardsProps } from 'types/types';

interface UpdateDashboardsSummaryProps {
  dash: DashboardsProps;
}

export const UpdateDashboardsSummary: React.FunctionComponent<UpdateDashboardsSummaryProps> = ({ dash }) => {
  const styles = useStyles2(getStyles);

  const { title, status } = dash;
  let color = getColorStatus(status!);

  return (
    <div
      className={`${styles.container}
        ${!status && styles.backgroundValidationDefault} 
        ${status === 'SUCCESS' && styles.backgroundValidationOk} 
        ${status === 'ERROR' && styles.backgroundValidationError}`}
    >
      {title} <span style={{ color: `${color}`, fontWeight: 600 }}>{status}</span>
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      padding: 4px 25px;
      margin: 2px;
      border-radius: 4px;
    `,
    backgroundValidationOk: css`
      background: #e1f8de;
    `,
    backgroundValidationError: css`
      background: #f8dee8;
    `,
    backgroundValidationDefault: css`
      background: white;
    `,
  };
};
