import React from 'react';
import { Button, useStyles2, Tooltip, Icon } from '@grafana/ui';
import { css } from '@emotion/css';
import { CONTENT_TOOLTIP } from 'utils/constants';
import { useAppState } from '../../app-context/AppStateContext';
import { CurrentProcessType } from 'types/types';

interface CreateDashboardsHeaderProps {}

export const CreateDashboardsHeader: React.FunctionComponent<CreateDashboardsHeaderProps> = () => {
  const { dispatch } = useAppState();
  const styles = useStyles2(getStyles);

  const goBackToMenu = () => {
    dispatch({ type: 'SET_CURRENT_PROCESS', payload: CurrentProcessType.null });
    dispatch({ type: 'SET_DASHBOARDS_CREATED', payload: [] });
  };

  return (
    <div className={styles.headerContainer}>
      <Button size={'sm'} onClick={() => goBackToMenu()} icon={'arrow-left'}>
        MENU
      </Button>
      <div className={styles.title}>
        DASHBOARD CREATION{' '}
        <Tooltip content={CONTENT_TOOLTIP}>
          <Icon name={'info-circle'} />
        </Tooltip>
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    headerContainer: css`
      display: flex;
      width: 100%;
      align-items: center;
    `,
    title: css`
      margin-left: 15px;
      font-size: 25px;
      font-weight: 500;
    `,
  };
};
