import { AppState, Action } from '../types/types';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'GET_OLD_JSON': {
      return {
        ...state,
        oldJson: action.payload,
      };
    }

    case 'GET_NEW_JSON': {
      return {
        ...state,
        newJson: action.payload,
      };
    }

    case 'GET_LANG': {
      return {
        ...state,
        lang: action.payload,
      };
    }

    case 'GET_MAC_ADDRESS': {
      return {
        ...state,
        macAddress: action.payload,
      };
    }

    case 'SET_ERROR': {
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.errorName]: action.payload.text,
        },
      };
    }

    case 'SET_IDCARD_VALUES': {
      return {
        ...state,
        idCardValues: action.payload,
      };
    }

    case 'SET_DASHBOARD_URL': {
      return {
        ...state,
        dashboardURL: action.payload,
      };
    }

    case 'IS_DASHBOARD_CUSTOMIZED': {
      return {
        ...state,
        isDashboardCustomized: action.payload,
      };
    }

    case 'IS_DASHBOARD_CREATED': {
      return {
        ...state,
        isDashboardCreated: action.payload,
      };
    }

    case 'SET_ANALYSIS_PROFILE': {
      return {
        ...state,
        analysisProfile: action.payload,
      };
    }

    case 'SET_DASHBOARDS_TO_UPDATE': {
      return {
        ...state,
        dashboardsToUpdate: action.payload,
      };
    }

    case 'SET_DASHBOARDS_CREATED': {
      return {
        ...state,
        dashboardsCreated: action.payload,
      };
    }

    case 'SET_CURRENT_PROCESS': {
      return {
        ...state,
        currentProcess: action.payload,
      };
    }

    case 'SET_ISO_CLASSIFICATIONS': {
      return {
        ...state,
        isoClassifications: action.payload,
      };
    }

    case 'ADD_DATASOURCES': {
      return {
        ...state,
        datasourceJson: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
