import { SelectableValue } from '@grafana/data';
import { TemplatesVar } from 'types/types';

/** Needed for batch creation */
export const getDefaultTemplateVars = (macAddress: string, lang: string, lengthUnit: SelectableValue) => {
  const constantTemplatesToInsert = [
    { name: TemplatesVar.beacon, label: 'Beacon', value: macAddress },
    { name: TemplatesVar.lang, label: 'lang_sel_label', value: lang },
    { name: TemplatesVar.X, label: null, value: 'X' },
    { name: TemplatesVar.Y, label: null, value: 'Y' },
    { name: TemplatesVar.Z, label: null, value: ' Z' },
    { name: TemplatesVar.image, label: null, value: '' },
    { name: TemplatesVar.lengthUnit, label: null, value: lengthUnit.value },
  ];

  let templatingList = constantTemplatesToInsert.map((template) => ({
    hide: 2,
    name: template.name,
    query: template.value,
    skipUrlSync: false,
    type: 'constant',
  }));

  return templatingList;
};
