import React from 'react';
import { useAppState } from '../app-context/AppStateContext';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

export interface UserInfosProps {}

export const UserInfos: React.FunctionComponent<UserInfosProps> = () => {
  const { state } = useAppState();
  const { user } = state;
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoxBox}>CURRENT ORGANIZATION : {user.orgName}</div>
    </div>
  );
};

const getStyles = () => {
  return {
    wrapper: css`
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
    `,
    infoxBox: css`
      padding: 8px;
      background-color: #f5f5f5;
      margin: 3px;
      font-size: 12px;
    `,
  };
};
