import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { Action, TemplatesVar } from '../types/types';

export const getJson = async (dispatch: (value: Action) => void, url: string) => {
  const results = await getBackendSrv()
    .get(url)
    .catch(() => {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          errorName: 'dashboardModel',
          text: 'Error while retrieving JSON model',
        },
      });
    });
  return results;
};

/**
 * Get json dashboard machine model
 * /public/asystom/asystom-dashboards/
 */
export const fetchNewDashboardJson = async (dispatch: (value: Action) => void, newProfile: string) => {
  let homeDashboardLang = 'EN';
  const dashboardLang = getTemplateSrv().replace(`$${TemplatesVar.lang}`);

  if (dashboardLang) {
    homeDashboardLang = dashboardLang;
  }

  const valveProfile = homeDashboardLang === 'EN' ? 'Valve' : 'Vanne';

  const urlDashboard =
    newProfile === valveProfile
      ? `/public/asystom/asystom-dashboards/dashboard_valve_${homeDashboardLang}.json`
      : `/public/asystom/asystom-dashboards/dashboard_${homeDashboardLang}.json`;

  const results = await getJson(dispatch, urlDashboard);

  if (!results) {
    dispatch({
      type: 'SET_ERROR',
      payload: {
        errorName: 'dashboardModel',
        text: `Error while retrieving JSON model (JSON ${homeDashboardLang} not found)`,
      },
    });
    return;
  }
  return results;
};
