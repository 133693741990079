import { IsoClassTranslationTypes, IsoClassificationsProps, TranslationLang } from './types';

export interface DicoProps extends IsoClassTranslationTypes {
  anomalyScore: string;
  class1: string;
  class2: string;
  class3: string;
  class4: string;
  battery: string;
  panelAlertTitle: string;
  vibratorySeverity: string;
  ambientTemperature: string;
  aliasAmbientTemperature: string;
  surfaceTemperature: string;
  valve: string;
  rotating: string;
  nonRotating: string;
  processValve: string;
  processRotating: string;
  typeValve: string;
  typeRotating: string;
  valveState: string;
  customThresholds: string;
  vibSeverityAlarm: string;
  vibSeverityTrip: string;
}

export const getTranslation = (lang: string, isoClassifications: IsoClassificationsProps) => {
  const translation: { [key: string]: { [key: string]: string } } = {
    anomalyScore: { EN: 'ANOMALY SCORE', FR: "SCORE D'ANOMALIE" },
    panelAlertTitle: { EN: 'ALERTS', FR: 'ALERTES' },
    battery: { EN: 'BATTERY', FR: 'BATTERIE' },
    vibratorySeverity: { EN: 'Vibratory severity', FR: 'Sévérité vibratoire' },
    ambientTemperature: { EN: 'Ambient temperature', FR: 'Température ambiante' },
    aliasAmbientTemperature: { EN: 'Ambient temperature', FR: 'Température ambiante' },
    surfaceTemperature: { EN: 'Surface temperature', FR: 'Temperature de surface' },
    valve: { EN: 'Valve', FR: 'Vanne' },
    rotating: { EN: 'Rotating', FR: 'Machine tournante' },
    nonRotating: { EN: 'Non-rotating', FR: 'Machine non-tournante' },
    processValve: { EN: 'Valve', FR: 'Vanne' },
    processRotating: { EN: 'Compressor', FR: 'Compresseur' },
    typeValve: { EN: 'Valve body', FR: 'Corps de vanne' },
    typeRotating: { EN: 'Motor', FR: 'Moteur' },
    valveState: { EN: 'Valve state', FR: 'État de la vanne' },
    customThresholds: { EN: 'Custom thresholds', FR: 'Seuils personnalisés' },
    vibSeverityAlarm: { EN: 'VIBRATORY SEVERITY (ALARM)', FR: 'SÉVÉRITÉ VIBRATOIRE (ALARM)' },
    vibSeverityTrip: { EN: 'VIBRATORY SEVERITY (TRIP)', FR: 'SÉVÉRITÉ VIBRATOIRE (TRIP)' },
  };

  let dico = {} as any;
  for (const [key, value] of Object.entries(translation)) {
    dico[key] = value[lang];
  }
  for (const [key, value] of Object.entries(isoClassifications.translations[`${lang}` as TranslationLang])) {
    dico[key] = value;
  }
  return dico as DicoProps;
};
