import React from 'react';

interface CreateDashboardsSummaryProps {
  dash: {
    creationStatus: string;
    title: string;
    url: string;
  };
}

export const CreateDashboardsSummary: React.FunctionComponent<CreateDashboardsSummaryProps> = ({ dash }) => {
  return (
    <div
      key={dash.url}
      style={{
        display: 'flex',
        margin: '2px',
        backgroundColor: dash.creationStatus === 'ERROR' ? '#f8dee8' : '#e1f8de',
      }}
    >
      <div style={{ fontWeight: 500, padding: '4px' }}>{dash.title}</div>
      <div style={{ fontWeight: 500, padding: '4px', color: dash.creationStatus === 'ERROR' ? '#ff428b' : '#099a5b' }}>
        {dash.creationStatus}
      </div>
      {dash.url && (
        <div style={{ cursor: 'pointer', padding: '4px' }} onClick={() => window.open(dash.url)}>
          VIEW DASHBOARD
        </div>
      )}
    </div>
  );
};
