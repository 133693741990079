import { getBackendSrv } from '@grafana/runtime';
import { DashboardsProps, LengthUnit, TemplatesVar } from 'types/types';
import { INCIDENT_ADVISOR_PLUGIN, URL_DASHBOARD_UID, URL_SEARCH_BY_TAG } from './constants';
import { findPanelIndexByType, getTemplate } from './helpers';

/** Get all dashboards by tag 'machine' and get their template variables  */
export const fetchDashboards = async () => {
  let newDashboardInfo: DashboardsProps[] = [];

  const result = await getBackendSrv().get(`${URL_SEARCH_BY_TAG}machine`);

  for (const [dashboardIndex, dashboard] of await result.entries()) {
    // Get dashboard json and extract general infos
    const resultDetails = await getBackendSrv().get(`${URL_DASHBOARD_UID}${dashboard.uid}`);

    // Retrieve dashboard informations
    const dashboardMacAddress = await getTemplate(resultDetails, TemplatesVar.beacon);
    const dashboardLang = await getTemplate(resultDetails, TemplatesVar.lang);
    const dashboardLengthUnit = await getTemplate(resultDetails, TemplatesVar.lengthUnit);
    const dashboardTitle = dashboard.title;
    const dashboardId = dashboard.id;
    const indexIa = findPanelIndexByType(resultDetails.dashboard.panels, INCIDENT_ADVISOR_PLUGIN);
    let analysisProfile = '';

    if (indexIa !== -1) {
      const indexAnalysisProfile = resultDetails.dashboard.panels[indexIa]?.idCardElements.findIndex(
        (element: { field: string }) => element.field === 'analysis_profile'
      );
      if (indexAnalysisProfile !== -1) {
        analysisProfile = resultDetails.dashboard.panels[indexIa].idCardElements[indexAnalysisProfile].value;
      }
    }

    newDashboardInfo[dashboardIndex] = {
      uid: dashboard.uid,
      title: dashboardTitle,
      id: dashboardId,
      macAddress: dashboardMacAddress,
      dashboardUrl: dashboard.url,
      dashboardJson: resultDetails,
      tags: resultDetails.dashboard.tags,
      lang: dashboardLang,
      status: '',
      created: resultDetails.meta.created,
      updated: resultDetails.meta.updated,
      hasIA: indexIa !== -1 ? true : false,
      previousLengthUnit: dashboardLengthUnit === LengthUnit.null ? LengthUnit.meter : dashboardLengthUnit,
      lengthUnit: dashboardLengthUnit === LengthUnit.null ? LengthUnit.meter : dashboardLengthUnit,
      hasLengthVariable: dashboardLengthUnit ? true : false,
      profile: analysisProfile,
      newProfile: analysisProfile,
    };
  }

  return newDashboardInfo;
};
