import { LengthUnit, TemperatureUnit } from 'types/types';

// grafana API
export const URL_SEARCH_BY_TAG = `api/search?query=&tag=`;
export const URL_DASHBOARD_UID = `api/dashboards/uid/`;
export const URL_SAVE_JSON = 'api/dashboards/db';
export const URL_GET_DATASOURCE_BY_NAME = 'api/datasources/name/';
export const URL_ISO_CLASSES = '/public/asystom/iso-classifications/classifications.json';

export const SIMPLEJSON_DATASOURCE = 'DashGen';

// panel types
export const INCIDENT_ADVISOR_PLUGIN = 'asystom-ad-ia';
export const OLD_IDENTITY_CARD_PLUGIN = 'asystom-idcard-writer';

export const MAC_ADDRESS_PREFIX = '00-04-A3-0B-';
export const MAC_ADDRESS_LENGTH = 23;

// INDEX is the position in the array,
// ID is the unique identifier given to the panel on dashboard creation

// panel index (position in alert panels array).
// Depends on model json (static position).
// These indexes need to be changed if JSON alert panels order is modified
export const INCIDENT_ADVISOR_PANEL_INDEX = 0;
export const ALERT_ROW_INDEX = 7;
export const VIB_SEV_ALERT_PANEL_INDEX = 1;
export const VIB_SEV_ALARM_PANEL_INDEX = 1;
export const VIB_SEV_TRIP_PANEL_INDEX = 3;
export const GENERAL_INFORMATION_ROW_INDEX = 3;
export const AMBIENT_TEMP_PANEL_INDEX = 2;
export const VIB_SOUND_TEMP_ROW_INDEX = 4;
export const SURFACE_TEMP_PANEL_INDEX = 2;

// panel id
const VIB_X_PANELID = 103;
const VIB_Y_PANELID = 105;
const VIB_Z_PANEL_ID = 107;
const VIBRMS_PANEL_ID = 49;
export const VIB_SEVERITY_ALERT_PANEL_ID = 139;
export const VIB_SEVERITY_TRIP_PANEL_ID = 166;

export const isPanelWithLengthUnit = (panelId: number) =>
  panelId === VIB_X_PANELID ||
  panelId === VIB_Y_PANELID ||
  panelId === VIB_Z_PANEL_ID ||
  panelId === VIBRMS_PANEL_ID ||
  panelId === VIB_SEVERITY_ALERT_PANEL_ID ||
  panelId === VIB_SEVERITY_TRIP_PANEL_ID;

export const CALC_INCH = '/ 25.4';
export const CALC_FAHRENHEIT = '* 9/5 + 32';

export const CELSIUS = 'Celsius (°C)';
export const FAHRENHEIT = 'Fahrenheit (°F)';

export const TEMPERATURES_OPTIONS = [
  { value: TemperatureUnit.celsius, label: CELSIUS },
  { value: TemperatureUnit.fahrenheit, label: FAHRENHEIT },
];

export const IN_S = 'in/s';
export const MM_S = 'mm/s';

export const LENGTH_OPTIONS = [
  { value: LengthUnit.meter, label: MM_S },
  { value: LengthUnit.inch, label: IN_S },
];

export const MYSQL_DATASOURCE = 'mysql_datasource';
export const INFLUX_DATASOURCE = 'asystom_db';

// thresholds
export const THRESHOLD_CLASS_I_MMS = 4.5;
export const THRESHOLD_CLASS_I_INS = 0.18;

export const CONTENT_TOOLTIP =
  'Creation of dashboards with default values >> machine_name (dashboard name): mac_address / class: Class I / process_function: Compressor / type: Motor / sensor orientation: - ↓ - ⟳ / analysis_profile: Rotating / tags : Machine.';
export const WRONG_MAC_ADDRESS_FORMAT =
  'Some mac addresses have a wrong format (a length of 23 is needed and mac address has to include 00-04-a3-0b-00-). Please check what is copied/pasted.';
export const NO_MAC_ADDRESS = 'No mac address.Please copy / past a list of mac addresses to create dashboards.';
export const TEXT_AREA_PLACEHOLDER = 'Copy / paste mac addresses from an excel sheet column. One mac address per line.';
export const noMacAddressText = 'no mac address';

export const getLengthUnitTemplate = (value: string) => ({
  hide: 2,
  name: 'length_unit',
  query: value,
  skipUrlSync: false,
  type: 'constant',
});

export const SESSION_ID_TEMPLATE = {
  current: {
    selected: false,
  },
  datasource: { type: 'mysql', uid: '' },
  definition: "SELECT IFNULL(max(id), '') FROM ai_session WHERE mac_address='$beacon_selection' ",
  hide: 2,
  includeAll: false,
  multi: false,
  label: null,
  name: 'session_id',
  options: [],
  query: "SELECT IFNULL(max(id), '') FROM ai_session WHERE mac_address='$beacon_selection' ",
  refresh: 1,
  regex: '',
  skipUrlSync: false,
  sort: 0,
  tagValuesQuery: '',
  tags: [],
  tagsQuery: '',
  type: 'query',
  useTags: false,
};

export const tableHeaders = [
  {
    title: 'UPDATE',
    width: '10%',
  },
  {
    title: 'DASHBOARD NAME',
    width: '30%',
  },
  {
    title: 'MAC ADDRESS',
    width: '20%',
  },
  {
    title: 'DETAILS',
    width: '5%',
  },
  {
    title: 'LENGTH UNIT',
    width: '10%',
  },
  {
    title: 'ANALYSIS PROFILE',
    width: '15%',
  },
  {
    title: 'URL',
    width: '10%',
  },
];

/**
 * Identity card elements FR
 * [0] class, [1] mac_address, [2] machine_name, [3] manufacturer,
 * [4] model, [5] process_function, [6] sensor_location, [7] sensor_orientation,
 * [8] type, [9] analysis _profile
 */

export const IdCardElementsFR = [
  {
    field: 'class',
    value: 'Classe I',
  },
  {
    field: 'mac_address',
    value: '',
  },
  {
    field: 'machine_name',
    value: '',
  },
  {
    field: 'manufacturer',
    value: '',
  },
  {
    field: 'model',
    value: '',
  },
  {
    field: 'process_function',
    value: 'Compresseur',
  },
  {
    field: 'sensor_location',
    value: '',
  },
  {
    field: 'sensor_orientation',
    value: '- ↓ - ⟳',
  },
  {
    field: 'type',
    value: 'Moteur',
  },
  {
    field: 'analysis_profile',
    value: 'Machine tournante',
  },
  {
    field: 'customIso',
    value: [],
  },
];

/**
 * Identity card elements EN
 * [0] class, [1] mac_address, [2] machine_name, [3] manufacturer,
 * [4] model, [5] process_function, [6] sensor_location, [7] sensor_orientation,
 * [8] type, [9] analysis _profile
 */

export const IdCardElementsEN = [
  {
    field: 'class',
    value: 'Class I',
  },
  {
    field: 'mac_address',
    value: '',
  },
  {
    field: 'machine_name',
    value: '',
  },
  {
    field: 'manufacturer',
    value: '',
  },
  {
    field: 'model',
    value: '',
  },
  {
    field: 'process_function',
    value: 'Compressor',
  },
  {
    field: 'sensor_location',
    value: '',
  },
  {
    field: 'sensor_orientation',
    value: '- ↓ - ⟳',
  },
  {
    field: 'type',
    value: 'Motor',
  },
  {
    field: 'analysis_profile',
    value: 'Rotating',
  },
  {
    field: 'customIso',
    value: [],
  },
];
