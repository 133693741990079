import { getDataSourceSrv, getTemplateSrv } from '@grafana/runtime';
import { DicoProps } from 'types/translation';
import { IsoClassificationsProps, LengthUnit, TemperatureUnit, TemplatesVar, ThresholdValues } from 'types/types';
import {
  AMBIENT_TEMP_PANEL_INDEX,
  CALC_FAHRENHEIT,
  CALC_INCH,
  GENERAL_INFORMATION_ROW_INDEX,
  INCIDENT_ADVISOR_PANEL_INDEX,
  INCIDENT_ADVISOR_PLUGIN,
  INFLUX_DATASOURCE,
  IN_S,
  isPanelWithLengthUnit,
  MYSQL_DATASOURCE,
  noMacAddressText,
  OLD_IDENTITY_CARD_PLUGIN,
  SURFACE_TEMP_PANEL_INDEX,
  VIB_SOUND_TEMP_ROW_INDEX,
} from './constants';

export const findAxis = (stringToSplit: string) => {
  if (typeof stringToSplit !== 'string') {
    return;
  }

  const removeSpaces = stringToSplit.split(' ');
  const findVerticalIndex = removeSpaces.findIndex((el) => el === '↓');
  const findRadialIndex = removeSpaces.findIndex((el) => el === '⟳');

  const verticalAxis = removeSpaces[findVerticalIndex - 1];
  const radialAxis = removeSpaces[findRadialIndex - 1];

  return { vertical: verticalAxis, radial: radialAxis };
};

/**
 * Transpose X, Y, Z to 'Vertical', 'Axial', 'Horizontal' or 'Axial-vertical'
 * Injected in template variables
 */

export const getOrientationValues = (orientation: string) => {
  interface ObjOrientation {
    value: string;
    increment: number;
  }

  interface Orientation {
    X: ObjOrientation;
    Y: ObjOrientation;
    Z: ObjOrientation;
  }

  const orientationObj: Orientation = {
    X: { value: 'X', increment: 0 },
    Y: { value: 'Y', increment: 0 },
    Z: { value: 'Z', increment: 0 },
  };

  const splitValue = orientation.split(' ');

  switch (splitValue[0]) {
    case 'X':
      orientationObj.X.value = 'Vertical';
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Vertical';
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Vertical';
      orientationObj.Z.increment += 1;
      break;
  }

  switch (splitValue[2]) {
    case 'X':
      orientationObj.X.value = 'Axial';
      if (orientationObj.X.increment > 0) {
        orientationObj.X.value = 'Axial - Vertical';
      }
      orientationObj.X.increment += 1;
      break;

    case 'Y':
      orientationObj.Y.value = 'Axial';
      if (orientationObj.Y.increment > 0) {
        orientationObj.Y.value = 'Axial - Vertical';
      }
      orientationObj.Y.increment += 1;
      break;

    case 'Z':
      orientationObj.Z.value = 'Axial';
      if (orientationObj.Z.increment > 0) {
        orientationObj.Z.value = 'Axial - Vertical';
      }
      orientationObj.Z.increment += 1;
      break;
  }

  let countZero = 0;
  for (const [, value] of Object.entries(orientationObj)) {
    if (value.increment === 0) {
      countZero += 1;
    }
  }

  if (countZero === 1) {
    for (const [key, value] of Object.entries(orientationObj)) {
      if (value.increment === 0) {
        // @ts-ignore
        // TS7053: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
        orientationObj[key].value = 'Horizontal';
      }
    }
  }
  return orientationObj;
};

export const injectIdCardValues = (oldJson: any, newJson: any, hasIA: boolean) => {
  interface VisibleItems {}

  /** Dashboard to update */
  const indexOfOldIdCard = findPanelIndexByType(oldJson.panels, OLD_IDENTITY_CARD_PLUGIN);
  const indexOfIdCardRecentDashboard = findPanelIndexByType(oldJson.panels, INCIDENT_ADVISOR_PLUGIN);
  const indexOfIncidentadvisor = findPanelIndexByType(newJson.dashboard.panels, INCIDENT_ADVISOR_PLUGIN);

  const newJsonIdCardValues = newJson.dashboard.panels[indexOfIncidentadvisor].idCardElements;

  if (hasIA) {
    newJson.dashboard.panels[indexOfIncidentadvisor].idCardElements =
      oldJson.panels[indexOfIdCardRecentDashboard].idCardElements;
    return newJson;
  } else {
    const indexOfAnalysisProfile = newJsonIdCardValues.findIndex(
      (element: { field: string }) => element.field === 'analysis_profile'
    );
    newJsonIdCardValues[indexOfAnalysisProfile].value = 'Rotating';

    const idCardValues = oldJson.panels[indexOfOldIdCard].visibleItems as VisibleItems;

    const applyIdCardValue = (key: string, newValue: string) => {
      const indexOfElement = newJsonIdCardValues.findIndex((element: { field: string }) => element.field === key);
      newJsonIdCardValues[indexOfElement].value = newValue;
      return newJsonIdCardValues;
    };

    for (const [key, value] of Object.entries(idCardValues)) {
      applyIdCardValue(key, value.strval);
    }
  }
  return newJson;
};

export const getTemplate = (results: any, templateName: string) => {
  const templateIndex = results.dashboard.templating.list.findIndex(
    (template: { name: string }) => template.name === templateName
  );
  if (templateIndex !== -1) {
    if (templateName === TemplatesVar.beacon && !results.dashboard.templating.list[templateIndex].query) {
      return noMacAddressText;
    }
    return results.dashboard.templating.list[templateIndex].query;
  } else {
    if (templateName === TemplatesVar.beacon) {
      return noMacAddressText;
    }
    if (templateName === TemplatesVar.lang) {
      return 'EN';
    }
    if (templateName === TemplatesVar.lengthUnit) {
      return LengthUnit.null;
    }
  }
};

/** Interpolate beacon_selection, apply unit changement (temperature and length) */
export const modifyQueries = async (
  newJson: { dashboard: { panels: any[] } },
  macAddress: string,
  temperatureUnit: string,
  lengthUnit: string,
  dico: DicoProps
) => {
  /**
   * Datasource details required by the new dashboard model introduced by grafana 10
   * grafana 7 (details hard-coded in the json as they are static)
   * { "datasource" : "asystom_db"}
   * grafana 10
   * { "datasource" : {"type": "influxdb", "uid": "XojYtz4"}}
   */

  const mysqlDs = await getDatasource(MYSQL_DATASOURCE);
  const influxDs = await getDatasource(INFLUX_DATASOURCE);

  const modifiedQueriesAndUnits = newJson.dashboard?.panels?.map(
    (
      panel: {
        targets: any;
        datasource: { type: string; uid: string };
        alert: any;
        type: string;
        panels: any[];
        title: string;
        id: number;
      },
      panelIndex: number
    ) => {
      if (panel?.datasource) {
        switch (panel.datasource.type) {
          case 'influxdb':
            panel.datasource.uid = influxDs.uid;
            break;
          case 'mysql':
            panel.datasource.uid = mysqlDs.uid;
            break;
          default:
            break;
        }
      }

      if (panel?.targets?.length) {
        // All panels are folded by default except Incident Advisor
        panel?.targets?.map(
          (
            target: { query: string; alias: string; datasource: { type: string; uid: string } },
            targetIndex: number
          ) => {
            if (target?.datasource) {
              switch (target.datasource.type) {
                case 'influxdb':
                  target.datasource.uid = influxDs.uid;
                  break;
                case 'mysql':
                  target.datasource.uid = mysqlDs.uid;
                  break;
                default:
                  break;
              }
            }

            if (target.query) {
              // Update temperature unit
              if (
                target?.alias?.toUpperCase() === dico.aliasAmbientTemperature?.toUpperCase() &&
                temperatureUnit === TemperatureUnit.fahrenheit
              ) {
                target.query = target.query.replace(`${CALC_FAHRENHEIT} `, '');
              }
              // Update length unit
              if (
                target?.alias?.toUpperCase()?.includes(dico.vibratorySeverity?.toUpperCase()) &&
                lengthUnit === LengthUnit.inch
              ) {
                target.query = buildNewQuery(target.query, 'FROM', `${CALC_INCH} FROM`);
              }
              newJson.dashboard.panels[panelIndex].targets[targetIndex].query = target.query.replace(
                '$beacon_selection',
                macAddress
              );
              return target;
            }
            return target;
          }
        );
        return panel;
      } else {
        if (panel.type === 'row' && panel?.panels?.length) {
          panel.panels.map((panelRow, panelRowIndex) => {
            if (panelRow?.datasource) {
              switch (panelRow.datasource.type) {
                case 'influxdb':
                  panelRow.datasource.uid = influxDs.uid;
                  break;
                case 'mysql':
                  panelRow.datasource.uid = mysqlDs.uid;
                  break;
                default:
                  break;
              }
            }

            const isChangingTemperaturePanels =
              (panelRow.title?.toUpperCase() === dico.ambientTemperature?.toUpperCase() ||
                panelRow.title?.toUpperCase() === dico.surfaceTemperature?.toUpperCase()) &&
              temperatureUnit === TemperatureUnit.fahrenheit;

            const isChangingLengthUnit = isPanelWithLengthUnit(panelRow.id) && lengthUnit === LengthUnit.inch;

            if (isChangingTemperaturePanels) {
              newJson.dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.unit = temperatureUnit;
              newJson.dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.max = null;
            }

            if (isChangingLengthUnit) {
              newJson.dashboard.panels[panelIndex].panels[panelRowIndex].fieldConfig.defaults.custom.axisLabel = IN_S;
              // newJson.dashboard.panels[panelIndex].panels[panelRowIndex].decimals = 4;
            }

            if (panelRow.targets) {
              panelRow?.targets?.map(
                (
                  targetRow: { query: string; alias: string; datasource: { type: string; uid: string } },
                  targetIndex: number
                ) => {
                  if (targetRow?.datasource) {
                    switch (targetRow.datasource.type) {
                      case 'influxdb':
                        targetRow.datasource.uid = influxDs.uid;
                        break;
                      case 'mysql':
                        targetRow.datasource.uid = mysqlDs.uid;
                        break;
                      default:
                        break;
                    }
                  }

                  if (targetRow.query) {
                    if (isChangingTemperaturePanels) {
                      targetRow.query = buildNewQuery(targetRow.query, 'FROM', `${CALC_FAHRENHEIT} FROM`);
                    }

                    if (isChangingLengthUnit) {
                      targetRow.query = buildNewQuery(targetRow.query, 'FROM', `${CALC_INCH} FROM`);
                      if (targetRow.alias === 'MaxScale') {
                        targetRow.query = queryMaxInch('$beacon_selection');
                      }
                    } else {
                      if (targetRow.query?.includes(CALC_INCH)) {
                        targetRow.query = targetRow.query.replace(CALC_INCH, '');
                      }
                    }

                    newJson.dashboard.panels[panelIndex].panels[panelRowIndex].targets[targetIndex].query =
                      targetRow.query.replace('$beacon_selection', macAddress);
                    return targetRow;
                  }
                  return targetRow;
                }
              );
              return panelRow;
            }
            return panelRow;
          });
        }
      }
      return panel;
    }
  );
  return modifiedQueriesAndUnits;
};

export const buildNewQuery = (query: string, splitElement: string, junctionString?: string) => {
  const splitQuery = query.split(splitElement);
  const newQuery = junctionString
    ? `${splitQuery[0]?.trim()} ${junctionString?.trim()} ${splitQuery[1]?.trim()}`
    : `${splitQuery[0]?.trim()} ${splitQuery[1]?.trim()}`;
  return newQuery;
};

export const getDatasource = async (datasourceName: string) => {
  const ds: any = await getDataSourceSrv()
    .get(datasourceName)
    .catch((err) => {
      console.error('Error while requesting mysql datasource', err);
      return;
    });

  return ds;
};

export const getDashboardLanguage = () => {
  let homeDashboardLang = 'EN';
  const dashboardLang = getTemplateSrv().replace(`$${TemplatesVar.lang}`);
  if (dashboardLang) {
    homeDashboardLang = dashboardLang;
  }
  return homeDashboardLang;
};

export const findPanelIndexByType = (panels: any[], type: string) => {
  const panelIndex = panels.findIndex((panel: { type: string }) => panel.type === type);
  return panelIndex;
};

export const findIndexById = (panelsArray: any[], idToFind: number) => {
  const indexId = panelsArray.findIndex((panel: { id: number }) => panel.id === idToFind);
  return indexId;
};

export const queryMaxInch = (beacon: string) =>
  `SELECT max(*) FROM (SELECT max(vib_x_f1) / 25.4,  max(vib_x_f2) / 25.4, max(vib_x_f3)/25.4, max(vib_y_f1) / 25.4,  max(vib_y_f2) / 25.4, max(vib_y_f3)/25.4, max(vib_z_f1) / 25.4, max(vib_z_f2) / 25.4, max(vib_z_f3)/25.4 FROM \"Signature\" WHERE \"device\"='${beacon}' AND $timeFilter)`;

export const queryMaxMillimeter = (beacon: string) =>
  `SELECT max(/vib_._f/) FROM \"Signature\" WHERE \"device\"='${beacon}' AND $timeFilter`;

export const getColorStatus = (status: string) => {
  let color = '';

  switch (status) {
    case 'SUCCESS':
      color = '#099a5b';
      break;
    case 'ERROR':
      color = '#ff428b';
      break;
    default:
      color = 'white';
  }
  return color;
};

export const convertTemperatureToFahrenheit = (newJson: { dashboard: { panels: any[] } }) => {
  /** As we know all the panels from the new json that have temperature queries, indexes can be hardcoded */
  // Incident advisor plugin Ambient Temperature update
  const ambientTempIndex = newJson.dashboard.panels[INCIDENT_ADVISOR_PANEL_INDEX].targets.findIndex(
    (target: { refId: string }) => target.refId === 'M'
  );
  newJson.dashboard.panels[INCIDENT_ADVISOR_PANEL_INDEX].targets[ambientTempIndex].query = buildNewQuery(
    newJson.dashboard.panels[INCIDENT_ADVISOR_PANEL_INDEX].targets[ambientTempIndex].query,
    'FROM',
    `${CALC_FAHRENHEIT} FROM`
  );

  // General information AMBIENT TEMPERATURE
  newJson.dashboard.panels[GENERAL_INFORMATION_ROW_INDEX].panels[AMBIENT_TEMP_PANEL_INDEX].targets[0].query =
    buildNewQuery(
      newJson.dashboard.panels[GENERAL_INFORMATION_ROW_INDEX].panels[AMBIENT_TEMP_PANEL_INDEX].targets[0].query,
      'FROM',
      `${CALC_FAHRENHEIT} FROM`
    );
  newJson.dashboard.panels[GENERAL_INFORMATION_ROW_INDEX].panels[AMBIENT_TEMP_PANEL_INDEX].fieldConfig.defaults.unit =
    TemperatureUnit.fahrenheit;
  newJson.dashboard.panels[GENERAL_INFORMATION_ROW_INDEX].panels[AMBIENT_TEMP_PANEL_INDEX].fieldConfig.defaults.max =
    null;

  // surface temperature
  newJson.dashboard.panels[VIB_SOUND_TEMP_ROW_INDEX].panels[SURFACE_TEMP_PANEL_INDEX].targets[0].query = buildNewQuery(
    newJson.dashboard.panels[VIB_SOUND_TEMP_ROW_INDEX].panels[SURFACE_TEMP_PANEL_INDEX].targets[0].query,
    'FROM',
    `${CALC_FAHRENHEIT} FROM`
  );
  // surface temperature (trend)
  newJson.dashboard.panels[VIB_SOUND_TEMP_ROW_INDEX].panels[SURFACE_TEMP_PANEL_INDEX].targets[1].query = buildNewQuery(
    newJson.dashboard.panels[VIB_SOUND_TEMP_ROW_INDEX].panels[SURFACE_TEMP_PANEL_INDEX].targets[1].query,
    'FROM',
    `${CALC_FAHRENHEIT} FROM`
  );
  newJson.dashboard.panels[VIB_SOUND_TEMP_ROW_INDEX].panels[SURFACE_TEMP_PANEL_INDEX].fieldConfig.defaults.unit =
    TemperatureUnit.fahrenheit;
};

/**
 * Check if previous dashboard has fahrenheit unit by
 * searching for AMBIENT TEMPERATURE panel (id = 14)
 */

const AMBIENT_TEMP_PANEL_INDEX_IN_DASHBOARD = 14;

export const checkPrevDashTemperatureUnit = (dashboardToUpdate: {
  dashboardJson: { dashboard: { panels: any[] } };
}) => {
  /**
   * Search for ambient temperature panel to determine unit temperature
   * Keep the panel in panelRowWithAmbTemp array
   */

  let indexOfAmbTemp = dashboardToUpdate.dashboardJson.dashboard.panels.findIndex(
    (panel: { id: number }) => panel.id === AMBIENT_TEMP_PANEL_INDEX_IN_DASHBOARD
  );

  let panelRowWithAmbTemp = [] as any;

  // if not found, consider collapsed panels and search for the panel is panel.panels
  if (indexOfAmbTemp === -1) {
    dashboardToUpdate.dashboardJson.dashboard.panels.forEach((panel: { panels: any[]; type: string }) => {
      if (panel.type === 'row' && panel.panels) {
        const indexOfAmbTempPanelRow = panel.panels.findIndex(
          (panelRow: { id: number }) => panelRow.id === AMBIENT_TEMP_PANEL_INDEX_IN_DASHBOARD
        );

        if (indexOfAmbTempPanelRow !== -1) {
          panelRowWithAmbTemp.push(panel.panels[indexOfAmbTempPanelRow]);
        }
      }
    });
  }

  let isFahrenheit = false;
  if (indexOfAmbTemp !== -1 && dashboardToUpdate.dashboardJson.dashboard.panels[indexOfAmbTemp]) {
    // dashboard json model from grafana 10
    if (
      dashboardToUpdate.dashboardJson.dashboard.panels[indexOfAmbTemp]?.fieldConfig?.defaults?.unit &&
      dashboardToUpdate.dashboardJson.dashboard.panels[indexOfAmbTemp]?.fieldConfig?.defaults.unit ===
        TemperatureUnit.fahrenheit
    ) {
      isFahrenheit = true;
    }

    // dashboard json model from grafana 7
    if (
      dashboardToUpdate.dashboardJson.dashboard.panels[indexOfAmbTemp]?.yaxes?.length &&
      dashboardToUpdate.dashboardJson.dashboard.panels[indexOfAmbTemp]?.yaxes[0]?.format === TemperatureUnit.fahrenheit
    ) {
      isFahrenheit = true;
    }
  }

  // Now, treat the content of panelRowWithAmbTemp array
  if (panelRowWithAmbTemp.length) {
    // dashboard json model from grafana 10
    if (panelRowWithAmbTemp[0]?.fieldConfig?.defaults?.unit === TemperatureUnit.fahrenheit) {
      isFahrenheit = true;
    }

    // dashboard json model from grafana 7
    if (
      panelRowWithAmbTemp[0]?.yaxes?.length &&
      panelRowWithAmbTemp[0]?.yaxes[0]?.format === TemperatureUnit.fahrenheit
    ) {
      isFahrenheit = true;
    }
  }
  return isFahrenheit;
};

export const getVibratoryThreshold = (
  classValue: string,
  dico: DicoProps,
  lengthUnit: string,
  isoClassifications: IsoClassificationsProps,
  customIso: string[]
) => {
  const { vibrationSeverityIsoIns, vibrationSeverityIsoMms } = isoClassifications;
  let thresholds = [] as any;
  const currentClass = classValue?.toUpperCase();

  switch (true) {
    // iso 10816
    case currentClass === dico.class1?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class1 : vibrationSeverityIsoIns.class1;
      break;
    case currentClass === dico.class2?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class2 : vibrationSeverityIsoIns.class2;
      break;
    case currentClass === dico.class3?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class3 : vibrationSeverityIsoIns.class3;
      break;
    case currentClass === dico.class4?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.class4 : vibrationSeverityIsoIns.class4;
      break;

    // iso 20816
    case currentClass === dico.a1?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a1 : vibrationSeverityIsoIns.a1;
      break;
    case currentClass === dico.a2?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a2 : vibrationSeverityIsoIns.a2;
      break;
    case currentClass === dico.a3?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a3 : vibrationSeverityIsoIns.a3;
      break;
    case currentClass === dico.a4?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.a4 : vibrationSeverityIsoIns.a4;
      break;
    case currentClass === dico.b1?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b1 : vibrationSeverityIsoIns.b1;
      break;
    case currentClass === dico.b2?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b2 : vibrationSeverityIsoIns.b2;
      break;
    case currentClass === dico.b3?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b3 : vibrationSeverityIsoIns.b3;
      break;
    case currentClass === dico.b4?.toUpperCase():
      thresholds = lengthUnit === 'meter' ? vibrationSeverityIsoMms.b4 : vibrationSeverityIsoIns.b4;
      break;

    case currentClass === dico.customThresholds?.toUpperCase():
      thresholds = { alarm: parseFloat(customIso[0]), trip: parseFloat(customIso[1]) };
      break;

    default:
      break;
  }
  return thresholds as ThresholdValues;
};
