export interface AppStateContextProps {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export type Action =
  | {
      type: 'GET_OLD_JSON';
      payload: any;
    }
  | {
      type: 'GET_NEW_JSON';
      payload: any;
    }
  | {
      type: 'GET_LANG';
      payload: string;
    }
  | {
      type: 'GET_MAC_ADDRESS';
      payload: string;
    }
  | {
      type: 'SET_ERROR';
      payload: {
        errorName: string;
        text: string;
      };
    }
  | {
      type: 'SET_DASHBOARD_URL';
      payload: string;
    }
  | {
      type: 'IS_DASHBOARD_CUSTOMIZED';
      payload: boolean;
    }
  | {
      type: 'SET_ANALYSIS_PROFILE';
      payload: string;
    }
  | {
      type: 'SET_IDCARD_VALUES';
      payload: any;
    }
  | {
      type: 'IS_DASHBOARD_CREATED';
      payload: boolean;
    }
  | {
      type: 'SET_DASHBOARDS_TO_UPDATE';
      payload: any;
    }
  | {
      type: 'SET_DASHBOARDS_CREATED';
      payload: NewdashboardsProps[];
    }
  | {
      type: 'SET_CURRENT_PROCESS';
      payload: CurrentProcessType;
    }
  | {
      type: 'SET_ISO_CLASSIFICATIONS';
      payload: IsoClassificationsProps;
    }
  | {
      type: 'ADD_DATASOURCES';
      payload: any;
    };
export interface OldIdCardProps {
  strval: string;
}

export interface NewdashboardsProps {
  title: string;
  url: string;
  creationStatus: string;
}

export interface DashboardsProps {
  uid: string;
  dashboardUrl: string;
  tags: string[];
  created: string;
  updated: string;
  id: number;
  title: string;
  macAddress: string;
  lang: string;
  dashboardJson: any;
  status?: string;
  hasIA: boolean;
  previousLengthUnit: string;
  lengthUnit: LengthUnit;
  hasLengthVariable: boolean;
  profile: string;
  newProfile: string;
}

export interface ThresholdValues {
  alarm: number;
  trip: number;
}

export enum IsoClasses {
  class1 = 'class1',
  class2 = 'class2',
  class3 = 'class3',
  class4 = 'class4',
  a1 = 'a1',
  a2 = 'a2',
  a3 = 'a3',
  a4 = 'a4',
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  default = 'default',
}

export enum TranslationLang {
  EN = 'EN',
  FR = 'FR',
}

export type IsoClassTypes = { [Key in IsoClasses]: ThresholdValues };
export type IsoClassTranslationTypes = { [Key in IsoClasses]: string };
export type TranslationsTypes = { [Key in TranslationLang]: IsoClassTranslationTypes };

export interface IsoClassificationsProps {
  vibrationSeverityIsoMms: IsoClassTypes;
  vibrationSeverityIsoIns: IsoClassTypes;
  translations: TranslationsTypes;
}

export interface AppState {
  user: {
    isGrafanaAdmin: boolean;
    orgName: string;
    orgRole: string;
  };
  oldJson: any;
  newJson: any;
  lang: string;
  macAddress: string;
  error: {
    dashboardModel: string;
    customizedDashboard: string;
    notJson: string;
    badRequest: string;
  };
  isDashboardCreated: boolean;
  isDashboardCustomized: boolean;
  dashboardURL: string;
  analysisProfile: string;
  idCardValues: any;
  dashboardsToUpdate: DashboardsProps[];
  dashboardsCreated: NewdashboardsProps[];
  currentProcess: CurrentProcessType;
  isoClassifications: IsoClassificationsProps;
  datasourceJson: any;
}

export enum CurrentProcessType {
  null = '',
  creation = 'creation',
  update = 'update',
}

export enum TemplatesVar {
  beacon = 'beacon_selection',
  image = 'imageFile',
  lang = 'lang_selection',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  lengthUnit = 'length_unit',
}

export enum LengthUnit {
  meter = 'meter',
  inch = 'inch',
  null = '',
}

export enum TemperatureUnit {
  celsius = 'celsius',
  fahrenheit = 'fahrenheit',
}

export enum ThresholdVibratorySeverityMmSec {
  CLASS_I = 4.5,
  CLASS_II = 7.1,
  CLASS_III = 11.2,
  CLASS_IV = 18,
  DEFAULT = 4.5,
}

export enum ThresholdVibratorySeverityInchSec {
  CLASS_I = 0.18,
  CLASS_II = 0.28,
  CLASS_III = 0.44,
  CLASS_IV = 0.7,
  DEFAULT = 0.18,
}
