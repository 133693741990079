import { getBackendSrv } from '@grafana/runtime';
import { Button, Input, useStyles2, Label } from '@grafana/ui';
import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { CurrentProcessType, DashboardsProps } from 'types/types';
import { useAppState } from 'app-context/AppStateContext';
import { UpdateDashboardsTable } from './UpdateDashoardsTable';
import { generateNewDashboard } from 'utils/generateNewDashboard';
import { UpdateDashboardsSummary } from './UpdateDashboardsSummary';
import { noMacAddressText, URL_ISO_CLASSES, URL_SAVE_JSON } from 'utils/constants';
import { fetchNewDashboardJson, getJson } from 'utils/fetchNewJson';
import { getTranslation } from 'types/translation';
import { fetchDashboards } from 'utils/fetchDashboards';
import { findIndexById } from 'utils/helpers';

interface UpdateDashboardsContainerProps {}

export const UpdateDashboardsContainer: React.FunctionComponent<UpdateDashboardsContainerProps> = () => {
  const { state, dispatch } = useAppState();
  const { dashboardsToUpdate, newJson, isoClassifications, datasourceJson } = state;

  const [dashboards, setDashboards] = useState([] as DashboardsProps[]);
  const [loading, setLoading] = useState(false);
  const [updateNote, setUpdateNote] = useState('Dashboard updated with Asystom Advisor version 2.5-rc2');
  const [isValidate, setIsValidate] = useState(false);
  const updatedDashboards = [...dashboardsToUpdate];

  const styles = useStyles2(getStyles);

  const getIsoClasses = async () => {
    const isoClasses = await getJson(dispatch, URL_ISO_CLASSES);
    if (!isoClasses) {
      console.log('Iso classifications file not found');
      return;
    }
    dispatch({ type: 'SET_ISO_CLASSIFICATIONS', payload: isoClasses });
  };

  /** Get all dashboards by tag 'Machine' */
  const searchDashboards = async () => {
    try {
      setLoading(true);
      let newDashboardInfo = await fetchDashboards();
      const dashboardsToUpdateList = newDashboardInfo.filter((dash) => dash.macAddress !== noMacAddressText);
      dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: dashboardsToUpdateList });
      setDashboards(newDashboardInfo);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  /** Send new dashboard to grafana and get url of the new dashboard */
  const sendJsonToGrafana = async (dashboardToSend: any, index: number) => {
    if (!Object.keys(dashboardToSend).length) {
      return;
    }
    await getBackendSrv()
      .post(URL_SAVE_JSON, dashboardToSend)
      .then((res) => {
        console.log('%c Status :', 'background: #222; color: #bada55', res.status);
        console.log('URL:', res.url);
        updatedDashboards[index].status = 'SUCCESS';
        dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: updatedDashboards });
      })
      .catch((e) => {
        updatedDashboards[index].status = 'ERROR';
        dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: updatedDashboards });
        console.error(e);
        setIsValidate(false);
        return;
      });
  };

  const processUpdate = async (dashboard: DashboardsProps) => {
    const jsonModel = await fetchNewDashboardJson(dispatch, dashboard.newProfile);

    if (!Object.keys(jsonModel).length) {
      console.log('no dashboard model');
    }

    const dashboardIndex = findIndexById(updatedDashboards, dashboard.id);
    if (dashboardIndex === -1) {
      console.error('Dashboard to update not found in queue', dashboard.title);
      return;
    }

    const dico = getTranslation(updatedDashboards[dashboardIndex].lang, isoClassifications);

    await generateNewDashboard(dashboard, jsonModel, updateNote, dico, isoClassifications, datasourceJson)
      .then((res) => {
        sendJsonToGrafana(res, dashboardIndex);
      })
      .catch((err) => {
        updatedDashboards[dashboardIndex].status = 'ERROR';
        dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: updatedDashboards });
        console.error(err);
        setIsValidate(false);
        return;
      });
  };

  const generateDashboards = async () => {
    for (const dashboard of updatedDashboards) {
      await processUpdate(dashboard);
    }
    setIsValidate(false);
  };

  const handleNoteChange = (e: any) => {
    setUpdateNote(e.target.value);
  };

  const goBackToMenu = () => {
    dispatch({ type: 'SET_CURRENT_PROCESS', payload: CurrentProcessType.null });
  };

  const deselectAllDashboards = () => {
    dispatch({ type: 'SET_DASHBOARDS_TO_UPDATE', payload: [] });
  };

  useEffect(() => {
    if (!isValidate) {
      return;
    }
    generateDashboards();
  }, [isValidate, newJson]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    searchDashboards();
    getIsoClasses();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button size={'sm'} onClick={() => goBackToMenu()} icon={'arrow-left'}>
          MENU
        </Button>
        <div className={styles.title}>UPDATE DASHBOARDS</div>
        <div className={styles.inputNoteContainer}>
          <div>
            <Label>Update note (appears in version table)</Label>
            <Input
              type="text"
              width={60}
              value={updateNote}
              onChange={(e) => handleNoteChange(e)}
              placeholder="Type update note (appears in version list)"
            />
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => setIsValidate(true)}
              disabled={!dashboardsToUpdate.length ? true : false}
            >
              UPDATE DASHBOARDS
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.topRowTable}>
        <Button variant={'secondary'} size={'sm'} onClick={deselectAllDashboards}>
          DESELECT ALL DASHBOARDS
        </Button>
        <div style={{ fontWeight: 500 }}>TOTAL OF DASHBOARDS : {dashboards.length}</div>
      </div>
      <div className={styles.updateContainer}>
        <UpdateDashboardsTable loading={loading} dashboards={dashboards} dashboardsToUpdate={dashboardsToUpdate} />
        <div className={styles.bottomList}>
          {dashboardsToUpdate &&
            dashboardsToUpdate.map((dash) => <UpdateDashboardsSummary dash={dash} key={dash.id} />)}
        </div>
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding: 25px;
      overflow: auto;
    `,
    bottomList: css`
      display: flex;
      flex-direction: column;
      overflow: auto;
      border-bottom: 2px solid #a4c3f6;
      padding: 0px 32px;
      background: aliceblue;
    `,
    inputNoteContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 8px;
    `,
    updateContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    topRowTable: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: #e5eefd;
      padding: 5px;
    `,
    title: css`
      margin-left: 15px;
      font-size: 25px;
      font-weight: 500;
    `,
    header: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
    `,
  };
};
