import React from 'react';
import { Spinner, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { UpdateDashboardRow } from './UpdateDashboardRow';
import { DashboardsProps } from '../../types/types';
import { tableHeaders } from '../../utils/constants';

interface DashboardsTableProps {
  loading: boolean;
  dashboards: DashboardsProps[];
  dashboardsToUpdate: DashboardsProps[];
}
const MIDDLE_GREY = '#646464';
const LIGHT_BACKGROUND = '#f2f2f2';

export const UpdateDashboardsTable: React.FunctionComponent<DashboardsTableProps> = ({
  loading,
  dashboards,
  dashboardsToUpdate,
}) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      <table className={styles.dashboardsTable}>
        {/* Table headers */}
        <tr>
          {tableHeaders.map((header) => (
            <th key={header.title} style={{ width: header.width }}>
              {header.title}
            </th>
          ))}
        </tr>
      </table>
      {/* Table content - all dashboards*/}
      <div className={styles.tableListContainer}>
        {loading && <Spinner />}
        <table className={styles.dashboardsTable}>
          {!loading &&
            dashboards.map((dash: DashboardsProps) => (
              <UpdateDashboardRow dashboard={dash} tableHeaders={tableHeaders} key={dash.id} />
            ))}
        </table>
        {/* Table footer - Total*/}
      </div>
      <table className={styles.totalTable}>
        <tr>
          <td className={styles.totalText}>{dashboardsToUpdate.length} DASHBOARDS TO UPDATE</td>
        </tr>
      </table>
    </>
  );
};

const getStyles = () => {
  return {
    tableListContainer: css`
      width: 100%;
      height: 420px;
      overflow: auto;
      border-bottom: 1px solid #c4c4c4;
    `,
    validation: css`
      background: aliceblue;
    `,
    totalTable: css`
      width: 100%;
      background: #e5eefd;
      color: #324aab;
    `,
    totalText: css`
      padding: 8px 25px;
      font-weight: 500;
    `,
    dashboardsTable: css`
      padding: 8px;
      width: 100%;
      border: 1px solid #e4e4e4;
      & > tr {
        color: ${MIDDLE_GREY};
        background: #f5f5f5;
        :nth-child(even) {
          background: #e4e4e4;
        }
        & > th {
          background: ${MIDDLE_GREY};
          padding: 8px;
          font-size: 14px;
          color: ${LIGHT_BACKGROUND};
          text-align: left;
          :first-child,
          :last-child,
          :nth-last-child(3),
          :nth-last-child(4) {
            text-align: center;
          }
        }
        & > td {
          padding: 3px;
          font-size: 13px;
          text-align: left;
          :first-child,
          :last-child,
          :nth-last-child(3),
          :nth-last-child(4) {
            text-align: center;
          }
        }
      }
    `,
  };
};
